import React, { useRef, useState, useEffect } from 'react'
import './contact.css'
import {HiOutlineMail} from 'react-icons/hi'
import emailjs from 'emailjs-com'

const Contact = () => {
  const form = useRef();
  const [showMessage, setShowMessage] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    emailjs.init(process.env.REACT_APP_EMAILJS_PUBLIC_KEY);
  }, []);

  const sendEmail = (e) => {
    e.preventDefault();
    setIsError(false);
    
    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      )
      .then(
        (result) => {
          setShowMessage(true);
          setIsError(false);
          setTimeout(() => setShowMessage(false), 3000);
          e.target.reset();
        },
        (error) => {
          setIsError(true);
          setShowMessage(true);
          setTimeout(() => setShowMessage(false), 3000);
        }
      )
      .catch(() => {
        setIsError(true);
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 3000);
      });
  };

  return (
    <section id='contact'>
      <h5 className='fuzuli'>Get in Touch</h5>
      <h2>cat /usr/contact</h2>

      <div className="container contact__container">
        <div className="contact__options">
          <article className='contact__option'>
            <HiOutlineMail className='contact__option-icon' />
            <h4>Email</h4>
            <h5>mail@muratzamir.net</h5>
            <a href="mailto:mail@muratzamir.net" target="_blank" rel="noopener noreferrer">Send a message</a>
          </article>
        </div>

        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name='name' placeholder='Full Name' required />
          <input type="email" name='email' placeholder='Email' required />
          <textarea name="message" rows="7" placeholder='Message' required ></textarea>
          <div className="submit-container">
            <button type='submit' className='btn btn-primary'>Send Message</button>
            {showMessage && (
              <span className={isError ? "error-message" : "success-message"}>
                {isError 
                  ? "Failed to send message. Please try again." 
                  : "Message sent successfully! ✓"}
              </span>
            )}
          </div>
        </form>
      </div>
    </section>
  )
}

export default Contact