import React from 'react'
import './about.css'
import {IoSchoolSharp} from 'react-icons/io5';
import {GiGreekTemple} from 'react-icons/gi';
import { AiOutlineSafetyCertificate } from "react-icons/ai";

const About = () => {
  return (
    <section id='about'>
      <h5 className='fuzuli'>Get to Know</h5>
      <h2>whoami</h2>

      <div className='container about__container'>
        <div className="about__content">
          <div className="about__cards">
            <article className='about__card'>
              <IoSchoolSharp className='about__icon'/>
              <h5>Purdue University | Alumni</h5>
              <small>Cybersecurity Major</small>
            </article>

            <article className='about__card'>
              <GiGreekTemple className='about__icon'/>
              <h5>Pi Kappa Alpha </h5>
              <small>Active Member</small>
            </article>

            <article className='about__card'>
              <AiOutlineSafetyCertificate className='about__icon'/>
              <h5>Certification</h5>
              <small>CompTIA Security+</small>
            </article>
          </div>

          <p>
          {'>_'} As an individual driven by an unwavering passion for cybersecurity, I am constantly improving my skills 
          to stay ahead of the curve in this evolving field. In addition to my professional pursuits, I engage in activities that 
          boost my energy and concentration; these include playing video games, reading books, and maintaining an active 
          lifestyle by going to the gym regularly. <br></br><br></br>

          {'>_'} My journey into technology began when I was a freshman in high school, where I co-founded a robotics 
          club that ignited my interest in coding with Arduino. The skills and experiences gained there not only improved my coding abilities but also sparked
          a curiosity about the broder applications of technology in our lives. I became intrigued by the challenges and opportunities presented by the intersection
          of technology and security. This led me to dive deeper into the cybersecurity world, where I discovered PortSwigger and HackTheBox as valuable resources 
          for mastering penetration testing and web application security concepts.<br></br><br></br>
          
          {'>_'} I pursue a degree in cybersecurity at Purdue University and proactively attended numerous security conferences to expand my 
          knowledge and network with other security enthusiast. My hunger for knowledge never ends, and I am always seeking opportunities to develop 
          a hacker mindset through dedication and hard work.
          </p>
        </div>
      </div>
    </section>
  )
}

export default About